import { graphql, navigate } from 'gatsby';
import React, { useState, useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Auth } from 'aws-amplify';
import { StateContext } from '../../lib/providers';
import { isValidPassword, isEmail, isNotEmpty } from '../../lib/validators';
import './scss/ForgotPassword.scss';
import CheckoutFormField from '../../components/checkout/CheckoutFormField';
import ConfirmationSignIn from '../../components/checkout/confirmation/ConfirmationSignIn';
import { ViewLayout } from '../../components/layout';

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default function ForgotPasswordPage() {
  const [email, setEmail] = useState('');
  const [emailErrors, setEmailErrors] = useState([]);
  const [confirmationForm, setConfirmationForm] = useState({
    code: '',
    password: '',
  });
  const [confirmationFormErrors, setConfirmationFormErrors] = useState([]);
  const [currentStep, setCurrentStep] = useState('email');

  const state = useContext(StateContext);
  const { user } = state || {};

  /**
   * Redirect if the user is detected signed in
   */
  useEffect(() => {
    if (window) {
      if (user) navigate('/dashboard');
    }
  }, [user]);

  const handleEmailInput = ({ target }) => {
    const { value } = target;
    setEmail(value);
  };

  const handleConfirmationFormInput = ({ target }) => {
    const { value, name } = target;
    setConfirmationForm((val) => ({ ...val, [name]: value }));
  };

  const progressStep = () => {
    setCurrentStep((val) => {
      switch (val) {
        case 'email':
          return 'code';
        case 'code':
          return 'signin';
        default:
          return '';
      }
    });
  };

  const handleEmailSubmit = (evt) => {
    evt.preventDefault();
    let errors = [];
    errors = isNotEmpty(email, []);
    errors = isEmail(email, []);
    setEmailErrors(errors);

    console.log('handleEmailSubmit', { evt, email, errors });
    if (errors.length) return;

    // Send confirmation code to user's email
    Auth.forgotPassword(email)
      .then((data) => {
        console.log(data);
        progressStep();
      })
      .catch((err) => {
        console.log(err);
        setEmailErrors([err.message]);
      });
  };

  const handleConfirmationFormSubmit = (evt) => {
    evt.preventDefault();
    let errors = [];
    errors = isNotEmpty(confirmationForm.password, []);
    errors = isValidPassword(confirmationForm.password, []);
    setConfirmationFormErrors({ code: [], password: errors });
    console.log('handleConfirmationFormSubmit', { evt, confirmationForm });
    if (errors.length) return;

    // Collect confirmation code and new password, then
    Auth.forgotPasswordSubmit(
      email,
      confirmationForm.code,
      confirmationForm.password
    )
      .then((data) => {
        console.log(data);
        progressStep();
      })
      .catch((err) => {
        console.log(err);
        setConfirmationFormErrors({ code: [err.message], password: errors });
      });
  };

  switch (currentStep) {
    case 'email':
      return (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Amwell Private Practice | Forgot Password</title>
          </Helmet>
          <ViewLayout type="forgotPassword">
            <div className="ForgotPassword">
              <div className="ForgotPassword-container">
                <EmailStep
                  value={email}
                  onSubmit={handleEmailSubmit}
                  onChange={handleEmailInput}
                  errors={emailErrors}
                />
              </div>
            </div>
          </ViewLayout>
        </>
      );
    case 'code':
      return (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Amwell Private Practice | Forgot Password</title>
          </Helmet>
          <div className="ForgotPassword">
            <div className="ForgotPassword-container">
              <ConfirmationStep
                value={confirmationForm}
                onChange={handleConfirmationFormInput}
                onSubmit={handleConfirmationFormSubmit}
                errors={confirmationFormErrors}
              />
            </div>
          </div>
        </>
      );
    case 'signin':
      return (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Amwell Private Practice | Forgot Password</title>
          </Helmet>
          <div className="ForgotPassword">
            <div className="ForgotPassword-container">
              <ConfirmationSignIn />
            </div>
          </div>
        </>
      );
    default:
      return null;
  }
}

const EmailStep = ({
  value,
  onSubmit: handleSubmit,
  onChange: handleChange,
  errors,
}) => (
  <form className="ForgotPassword-step" onSubmit={handleSubmit}>
    <p className="ForgotPassword-step-heading">
      Please enter your email address
    </p>
    <CheckoutFormField
      label={'Email'}
      name={'email'}
      type="email"
      value={value}
      id={`forgotPassword-email`}
      onChange={handleChange}
      errors={errors}
    />
    <button type="submit" className="ForgotPassword-button">
      Continue
    </button>
  </form>
);

const ConfirmationStep = ({
  value: form,
  onSubmit: handleSubmit,
  onChange: handleChange,
  errors,
}) => (
  <form className="ForgotPassword-step" onSubmit={handleSubmit}>
    <p className="ForgotPassword-step-heading">
      A confirmation code has been sent to your email. Please enter that code in
      the field below
    </p>
    <CheckoutFormField
      label={'Confirmation Code'}
      name={'code'}
      type="text"
      value={form.code}
      id={`forgotPassword-code`}
      onChange={handleChange}
      errors={errors.code}
    />
    <CheckoutFormField
      label={'New Password'}
      name={'password'}
      type="password"
      value={form.password}
      id={`forgotPassword-password`}
      onChange={handleChange}
      errors={errors.password}
    />
    <button type="submit" className="ForgotPassword-button">
      Continue
    </button>
  </form>
);
